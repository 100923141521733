<template>
  <div class="wrapper-timeline">
    <b-img
      :src="timelineImage"
      style="width: 100%;"
    />
  </div>
</template>

<style scoped>

.wrapper-timeline {
  margin-bottom: 24px;
}

</style>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import timelineImage from '@/assets/images/pages/timeline-fisip.png'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      timelineImage,
    }
  },
}
</script>
